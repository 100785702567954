import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'

function NotFoundPage({ location }) {
	// Page-Title Override
	// const pageTitle = location ? location.pathname.replace(/\//g, '') : ''
	const pageTitle = "...Re-Directing..."

	return (
		<Layout id="Top" location={location} title={pageTitle}>
        {/* <h1>Page Not Found!</h1>
        <p>Sorry: That route does not exist yet...</p> */}
		</Layout>
	)
}

NotFoundPage.propTypes = {
	location: PropTypes.object,
}

export default NotFoundPage
